<template>
    <b-card-code no-body title="All toolkits">
        <div class="ml-2">
            <b-button :to="{name: 'learn-and-develop-create'}" variant="primary">Add toolkit</b-button>
        </div>
        <div class="m-2">
            <!-- Table Top -->
            <div class="d-flex align-items-center justify-content-between mt-2">
                <!-- Per Page -->
                <div cols="6" md="6">
                    <!-- page length -->
                    <b-form-group
                        label="Show"
                        label-cols="5"
                        label-align="left"
                        label-size="sm"
                        label-for="sortBySelect"
                        class="text-nowrap mb-md-0 mr-1"
                    >
                        <b-form-select
                            id="perPageSelect"
                            v-model="perPage"
                            size="sm"
                            inline
                            :options="pageOptions"
                        />
                    </b-form-group>
                </div>
                <div>
                    <b-form-input
                        id="input-23"
                        v-model="searchToolkit"
                        type="text"
                        size="sm"
                        placeholder="Search toolkit"
                        required
                    />
                </div>
            </div>
        </div>

        <!-- Table -->
        <b-table
            striped
            hover
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="filteredList"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
        >
            <!-- Column: Title -->
            <template #cell(name)="data">
                <router-link :to="{name: 'learn-and-develop-edit', params: {id: data.item.id} }">
                    <span class="font-weight-bold d-block text-nowrap">
                        {{ data.item.title }}
                    </span>
                </router-link>
            </template>

            <!-- Column: Category -->
            <template #cell(category)="data">
                <span class="font-weight-bold d-block text-nowrap" v-if="data.item.category">
                    {{ data.item.category.name}}
                </span>
            </template>

            <!-- Column: Worksheets -->
            <template #cell(worksheets)="data">
                <span class="font-weight-bold d-block text-nowrap">
                    {{ data.item.worksheets.length }}
                </span>
            </template>
            
            <!-- Column: Published -->
            <template #cell(published)="data">
                <span class="font-weight-bold d-block text-nowrap" v-if="data.item.published">
                    <b-badge variant="light-success">
                        Yes
                    </b-badge>
                </span>
                <span class="font-weight-bold d-block text-nowrap" v-else>
                    <b-badge variant="light-danger">
                        No
                    </b-badge>
                </span>
            </template>
            
            <!-- Column: Created at -->
            <template #cell(created_at)="data">
                <span class="font-weight-bold d-block text-nowrap">
                    {{ data.item.created_at | prepareDate}}
                </span>
            </template>

            <template #cell(actions)="data">
                <b-badge variant="light-danger" @click="removeToolkit(data.item.id)" v-b-tooltip.hover.top="'Remove toolkit'">
                    <feather-icon icon="Trash2Icon" />
                </b-badge>
                <router-link :to="{name: 'learn-and-develop-edit', params: {id: data.item.id} }" v-b-tooltip.hover.top="'Edit toolkit'">
                    <b-badge variant="light-primary" class="ml-05">
                        <feather-icon icon="EditIcon" />
                    </b-badge>
                </router-link>
            </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                >
                <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
                </b-pagination>
            </div>
        </b-card-body>
    </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  VBTooltip,
} from "bootstrap-vue";
export default {
    components: {
        BRow,
        BCol,
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCardBody,
        BCardCode
    },
    directives: {
        'b-tooltip': VBTooltip,
    },
    data() {
        return {
            data: [],
            searchToolkit: "",
            perPage: 10,
            pageOptions: [3, 5, 10],
            totalRows: 1,
            currentPage: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            fields: [
                { key: "name", label: "Full Name" },
                { key: "category", label: "Category" },
                { key: "worksheets", label: "Worksheets", class: "text-center" },
                { key: "published", label: "Published", class: "text-center" },
                { key: "created_at", label: "Created" },
                { key: "actions", label: "Actions", class: "text-center" },
            ],
        };
    },
    computed: {
        filteredList() {
            let searchTerm = this.searchToolkit;
            let countToolkits = 0

            return this.data.filter(toolkit => {
                if(toolkit.title == null) {
                    toolkit.title = ""
                }

                if(toolkit.title.toLowerCase().includes(searchTerm.toLowerCase())) {
                    // Update total rows
                    countToolkits++
                    this.totalRows = countToolkits
                    
                    return toolkit;
                }
            });       
        }
    },
    methods: {
        getAllToolkits() {
            //Return toolkit list
            this.$http.get("/api/auth/admin/toolkits")
                .then((res) => {
                    if(res != undefined) {
                        this.data = res.data
                        // Set the initial number of items
                        this.totalRows = this.data.length;
                    }
                })
                .catch((err) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Something went wrong, please login again",
                            icon: "AlertCircleIcon",
                            variant: "danger",
                        },
                    });
                })
        },
        removeToolkit(toolkitId) {
            this.$bvModal
                .msgBoxConfirm('All data for the selected toolkit will be deleted', {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {        
                if(value == true) {
                    this.$http.delete("/api/auth/admin/toolkits/" + toolkitId)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The toolkit was successfully deleted!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });     
                        this.getAllToolkits();                 
                    }).catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error deleting the toolkit",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        },
    },
    mounted() {
        this.getAllToolkits();
    }
}
</script>